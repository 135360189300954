import React from "react"
import { Helmet } from "react-helmet"
import StandardLayout from "../layout/StandardLayout";

export default () => <StandardLayout>

    <Helmet>
        <title>Terms & Conditions - Burke Shipping Services</title>
    </Helmet>

    <div className="u-fill-1 u-pad-large u-pad-xlarge@m">
        <div className="l-wrap u-width-5">
            <p className="u-font-bold u-marg-bottom">Burke Shipping Services Ltd.</p>
            <h1 className="c-heading +h1">General Terms and Conditions of Trading</h1>
        </div>
    </div>

    <div className="l-wrap u-pad-large u-pad-xlarge@m u-width-5 s-cms-content u-marg-bottom-large">

    <p>
    Burke Shipping Services Limited (hereafter referred to as ·the company') undertakes all services subject solely to
    the following conditions, which can be varied only in writing by a director or partner or duly authorised servant
    of the company The customer's (the term customer covers customers employees and agents both disclosed and
    undisclosed) attention is drawn  in particular to condition 3.2.
    </p>

    <p>
    Condition 3.2 has been included herein solely to relieve the Owner of the goods the subject of the contract
    ('the goods'), or the owner's agent, of the additional costs that the company would need to include to recover
    insurance charges were its liability not limited as provided for in condition 3.2. Condition 3.2 will become
    operative at the option of the customer on the terms provided therein
    </p>

    <p>
        <span className="u-font-bold">1.</span> The company is not a common carrier and the rights and liabilities of the parties here under shall be determined
    on the basis that the company is not such a common carrier.
    </p>

    <p>
    <span className="u-font-bold">2.1</span> The customer warrants that it is either the Owner of the goods or is authorised by such Owner to accept these
    conditions on the owner's behalf
    </p>

    <p>
    <span className="u-font-bold">2.2</span> The customer further warrants that
    </p>

    <div className="u-indent-1 u-marg-bottom-large">
        <p>
        <span className="u-font-bold">(a)</span> when presented for warehousing the goods will be securely and properly packed and in such condition as not to
        cause damage or injury or the likelihood of damage or injury to the property of the company or to any other good,
        whether by spreading of damp, infestation, leakage or the escape of fumes or substances or otherwise howsoever.
        </p>

        <p>
        <span className="u-font-bold">(b)</span> before presentation of the goods for warehousing the customer will inform the company in writing of any special
        precautions necessitated by the nature or condition of the goods and of any statutory duties specific to the goods
        with which the company may need to comply.
        </p>
    </div>

    <p>
    <span className="u-font-bold">2.3</span> The customer acknowledges that these conditions have not been entered into in reliance wholly or partly in
    any statement or representation made by or on behalf of the company except any such statement or representation
    that is expressly set out in these conditions.
    </p>


    <h2 className="c-heading h3 u-font-bold ">Liability</h2>

    <p>
    <span className="u-font-bold">3.1</span> except as provided in condition 3.3 below the company does not insure the goods and the customer should make
    arrangements to coyer the goods against all risks to the full insurable value thereof
    </p>

    <p>
    <span className="u-font-bold">3.2</span> The company is only responsible for any loss or damage to goods or for any non-delivery, mis-delivery or
    delay or for any non-compliance with instructions if the same has arisen due to the wilful default of the
    company, its servants or agents. provided always that the liability of the company shall in no case exceed
    a total of £1000 sterling per tonne weight or that part of the goods in respect of which a claim arises. The
    company shall in no case be liable for any indirect consequential, special, or punitive-loss or damage of any
    kind whatsoever ( including but not limited to lost profits, loss of business. depletion of goodwill and/or
    similar losses). whether or not foreseeable, even if the company has been advised of the likelihood of such
    loss or damage and regardless of whether the claim for loss or damage is made in negligence, for breach of
    contract breach of trust or otherwise.
    </p>

    <p>
    <span className="u-font-bold">3.3</span> The company shall not be liable for any claim unless it has been notified in writing to the company by the
    customer within 21 days of the cause of the claim coming to the customer's knowledge or of the goods being
    delivered by the company to or to the use of the customer, whichever is the later.
    </p>

    <p>
    <span className="u-font-bold">3.4</span> The company shall not be liable hereunder for any loss or damage to the extent that the same is caused or
    contributed to by a breach of any of customer's warranties contained in condition 2 (or by any of the circumstances
    by virtue of which the company is relieved of its contractual obligations in accordance with condition l0)
    </p>

    <p>
    <span className="u-font-bold">3.5</span> In any event the company shall not incur any liability in respect of
    </p>

    <div className="u-indent-1 u-marg-bottom-large">
        <p>
            <span className="u-font-bold">(a)</span> damage wholly or partly resulting from the nature and or condition of the means of transport or the nature
            and/or condition of the goods:
        </p>

        <p>
            <span className="u-font-bold">(b)</span> damage which is unavoidable in the applied method of working indicated to the customer if no objection has
            been raised to such method by or on behalf of the customer of those interested:
        </p>

        <p>
            <span className="u-font-bold">(c)</span> damage due to any difference in weight. quality. contamination, mingling including damage caused by dust
            and/or presence of foreign objects or particles in the goods unless caused by the gross negligence or recklessness
            of the company or its employees:
        </p>

        <p>
            <span className="u-font-bold">(d)</span> damage caused by or resulting from work other than the loading, Unloading, Handling, storage warehousing,
            stockpiling and delivery. This exemption includes such activities as placing of lamps, carrying out repairs,
            manipulating hatches, beams, cross beams or moving or removing vessels or vehicles
        </p>

        <p>
            <span className="u-font-bold">(e)</span> damage resulting from failure breakage or other defect of the equipment used by the company unless it can be
            proved that the equipment was not in a good state of maintenance and did not conform to the requirements if any
            laid down by the relevant authorities in this respect
        </p>

        <p>
            <span className="u-font-bold">(f)</span> damage resulting from non-compliance by third parties with regulations laid down by the relevant authorities
            or issued by the company:
        </p>

        <p>
            <span className="u-font-bold">(g)</span> damage arising from any act or any neglect of a third party whether or not the third party was employed by or
            behalf of the company:
        </p>

        <p>
            <span className="u-font-bold">(h)</span> damage done to other goods on board during the performance of the services by the company other than damage
            resulting from the gross negligence or wilful default of the company or its employees:
        </p>

        <p>
            <span className="u-font-bold">(i)</span> Despatch money lost or demurrage due in respect of barges, lighters. railway wagons, trucks. unless caused by
            the gross negligence or wilful default of the company or its employees:
        </p>

        <p>
            <span className="u-font-bold">(j)</span> Delay arising from the repair of damage where such repair is carried out during or after completion of loading
            or unloading unless the damage has been caused by the gross negligence or wilful default of the company or it
            employees
        </p>
    </div>


    <p>
    <span className="u-font-bold">3.6</span> The benefit of these conditions shall extend to all employees from time to time of the company. who shall each
    be entitled to every right. defence and exemption or limitation of liability to which the company is entitled
    hereunder.
    </p>

    <p>
    <span className="u-font-bold">4.1</span> In all circumstances. the company shall be entitled to arrange for any part of the service to be performed
    by other contractors and in this event, these terms and conditions shall apply to such services The company shall
    not however be responsible for any act or default of such delegates.
    </p>

    <p>
    <span className="u-font-bold">4.2</span> The customer shall reimburse all duties and taxes that the company may be required to pay in respect of the
    goods, except to the extent that the company is required to accept any responsibility for them in accordance with
    condition 3.
    </p>

    <p>
    <span className="u-font-bold">5.</span> Notwithstanding any notice given in accordance with condition 3. the customer shall indemnify the company
    against any loss, costs, suits or damage suffered by the company including costs and expenses reasonably
    incurred by it to the extent that such loss or damage is caused or contributed to by a breach of any of the
    customer's warranties contained in condition 2. The customer agrees to be responsible for and to keep the
    company fully indemnified against all damages, losses, costs, expenses, actions, demands, proceedings,
    claims and liabilities made against or suffered or incurred by the company arising directly or indirectly
    out of any act omission or negligence of the customer or any persons at the premises expressly or impliedly
    with the customer's authority and under the customer's control.
    </p>

    <p>The premises being Burke Shipping Services Limited. Lisahally Terminal. Londonderry</p>

    <p>
    <span className="u-font-bold">6.</span> When reasonably necessary. vehicles or other means of conveyance may be changed and may deviate or depart
    from their intended route and at the discretion of the company. the goods may be carried. stored or handled
    with other compatible goods or transferred between stores
    </p>

    <p>
    <span className="u-font-bold">7.</span> The company shall not be liable for any loss or damage whatsoever arising to marble. articles of glass or
    articles contained in glass or any goods of a fragile nature
    </p>

    <h2 className="c-heading h2 u-font-bold">Charges, payments and liens</h2>

    <p>
    <span className="u-font-bold">8.</span> The company's charges. which may be increased from time to time by not less than 21 days prior notice to the
    customer, shall be payable at such intervals or on the expiry of such period of credit as may have been agreed
    between the parties or. in any event. before removal of the goods from the company's custody or control. Interest
    on amounts due and unpaid shall be payable from the date when payment of such amounts fell due and shall be
    calculated on a daily basis at the rate of 2% per annum above the prevailing base rate current from time to
    time Further. the company shall have a general as well as a particular lien on the goods for payment of all
    amounts due from the customer on any account
    </p>

    <h2 className="c-heading h3 u-font-bold">Termination</h2>

    <p>
    <span className="u-font-bold">9.1</span> The goods shall be removed by the customer from the custody of the company at such time otherwise where
    reasonably necessary. the company may at anytime by notice in writing to the customer require the removal of
    the goods within 28 days from the date of such notice or. in the case of perishable goods within three days.
    </p>

    <p>
    <span className="u-font-bold">9.2</span> In the event of failure by the customer to pay any amount due to the company or to remove any of the goods
    from the custody or control of the company (notice in accordance with condition 9 having been given) at the due
    time. the company may. without prejudice to its other rights and remedies against the customer. give notice in
    writing to the customer of the company's intention to sell otherwise dispose of the goods at the customer's
    entire risk and expense if such amount is not paid and/or such goods are not removed within 28 days. or in the
    case of perishable goods. within three days from the date of such notice. On the expiry of such period. if such
    payment has not been made and/or goods have not been so removed. the company shall be entitled to sell or
    otherwise dispose of all or any part of the goods at the customer's entire risk and expense by the best method
    reasonably available and the proceeds of any sale or disposal shall be remitted to the customer after deduction
    therefrom of all expenses and all amounts due to the company from the customer on any account
    </p>

    <div className="u-indent-1 u-marg-bottom-large">

        <p>
            <span className="u-font-bold">(a)</span> the company may as the agent of the customer sell such property and the customer will indemnify the company
            against any liability incurred by it to any third party whose property shall have been sold by the company in the
            mistaken belief albeit held in good faith (which shall be presumed unless the contrary be proved) that such
            property belonged to the customer.
        </p>

        <p>
            <span className="u-font-bold">(b)</span> if the company having made reasonable efforts is unable to locate the customer. the company shall be entitled
            to retain such proceeds of sale absolutely unless the customer shall claim them within 6 months of the date of
            such notice.
        </p>

        <p>
            <span className="u-font-bold">(c)</span> the customer shall indemnify the company against any damage occasioned to the premises any damages. losses.
            costs. expenses. actions. demands. proceedings. claims. and liabilities made against the company caused by or in
            relation to the presence of the goods in or on the premises.
        </p>

    </div>

    <p>
    <span className="u-font-bold">9.3</span> In the case of perishable goods. notice under condition 9.2 May be combined with a notice if any, under
    condition 9.1
    </p>

    <h2 className="c-heading h3 u-font-bold">Responsibility</h2>

    <p>
    <span className="u-font-bold">10.</span> The company shall be relieved of the contractual obligations to the extent that their performance is prevented
    by. or their non-performance is the direct or indirect consequence of the act. neglect. omission. or default of
    the customer. including any breach by the customer of the warranties contained in condition 2 or by storm. flood.
    fire. explosion. riot. industrial dispute. labour disturbance failure of a utility service or transport network.
    act of God. war. riot civil commotion malicious damage. compliance with any law or governmental order. rule.
    regulation or direction. accident. breakdown of plan or machinery, fire. flood. storm. high or low water or
    default of suppliers or sub-contractors or other cause beyond the reasonable control of the company
    </p>

    <p>
    <span className="u-font-bold">11.</span> Any notice or statement of account given by the company to the customer shall be duly given if left at or
    sent by registered or recorded delivery to the last known address of the customer and such notice of account
    shall if posted. be deemed to have been given two week days after posting.
    </p>

    <p>
    <span className="u-font-bold">12.</span> These conditions shall prevail over any terms or conditions contained in the customer's order. acceptance or
    other communication and shall be deemed to have been accepted by the customer in preference to such other terms
    or conditions. unless the customer has notified the company specifically in writing of any proposed variation of
    these conditions and such variation has been agreed specifically in writing by a director, partner or duly
    authorised servant of the company.
    </p>

    <p>
    <span className="u-font-bold">13.</span> The customer shall be liable to pay to the company. on demand. all reasonable costs, charges or losses
    sustained or Incurred by the company (including any direct. indirect or consequential losses. loss of profit
    and loss of reputation. loss or damage to property and those arising from injury to or death of any person and
    loss of opportunity to deploy resources elsewhere) arising directly or indirectly from the customer's fraud,
    negligence. failure to perform or delay in the performance of any of its obligations under the agreement subject
    to the company confirming such costs. charges and losses to the customer in writing
    </p>

    <h2 className="c-heading h3 u-font-bold">Payment on Termination</h2>

    <p>
    <span className="u-font-bold">14.</span> On termination of the agreement for any reason:
    </p>

    <div className="u-indent-1 u-marg-bottom-large">

        <p>
            <span className="u-font-bold">(a)</span> The customer shall immediately pay to the company all of the company's outstanding unpaid invoices and
            interest and, in respect of services supplied but for which no invoice has been submitted. the company may
            submit an invoice. which shall be payable immediately on receipt
        </p>

        <p>
            <span className="u-font-bold">(b)</span> The accrued rights of the parties as at termination and the continuation of any provision expressly stated to
            survive or implicitly surviving termination. shall not be affected.
        </p>

    </div>

    <h2 className="c-heading h3 u-font-bold">Jurisdiction</h2>

    <p>

    <span className="u-font-bold">15.</span> These conditions and any act or contract to which they apply shall be governed by the laws of the Northern
    Ireland and any dispute arising out of any act or contract to which these conditions apply shall be subject
    to the exclusive jurisdiction of the courts of Northern Ireland.
    </p>

    </div>

    <div className="u-fill-1 u-pad-large u-pad-xlarge@m">
        <div className="l-wrap u-width-5">
            <p className="u-font-bold u-marg-bottom">Burke Shipping Services Ltd.</p>
            <h1 className="c-heading +h1">Stevedoring division standard trading conditions</h1>
        </div>
    </div>

    <div className="l-wrap u-width-5 u-pad-large u-pad-xlarge@m s-cms-content u-marg-bottom-large">

    <p>
    <span className="u-font-bold">1</span> in these conditions:
    </p>

    <div className="u-indent-1 u-marg-bottom-large">

        <p>
            <span className="u-font-bold">(a)</span> "the company"' means Burke Shipping Services Limited and any subsidiary which may adopt these conditions as
            the case may be:
        </p>

        <p>
            <span className="u-font-bold">(b)</span> "customer" means the person for whom any business is done by the company or with whom any contract for the
            rendering of services by the company is made:
        </p>

        <p>
            <span className="u-font-bold">(c)</span> "dangerous goods" means:
        </p>

        <div className="u-indent-1 u-marg-bottom-large">
            <p>
            <span className="u-font-bold">1.</span> Goods which may or might. however remotely. be likely in the opinion of the company to cause damage.
            deterioration. or diminution in value to other goods. persons. or property.
            </p>
            <p>or</p>
            <p>
                <span className="u-font-bold">2.</span> Any goods classified by the international maritime organisation as dangerous for man and I or the environment
            </p>
        </div>

        <p>
            <span className="u-font-bold">(d)</span> valuables" shall include bullion. coins. precious stones. jewellery. antiques. pictures. bank notes.
            securities and other valuable documents and articles.
        </p>

        <p>
            <span className="u-font-bold">(e)</span> "containerised goods" means any standard iso or non-standard freight container (including but not limited
            to dry vans. reefer containers and out of Gauge containers) and their contents:

            <div className="u-indent-1 u-marg-bottom-large">
                <p>
                    <span className="u-font-bold">2.</span> The company are stevedores and act solely as such in performing services for the customer
                </p>
                <p>or</p>
                <p>
                    <span className="u-font-bold">3.</span> These conditions shall apply to all business undertaken by the company including any advice. information or
                    service provided whether gratuitously or not.  All other terms and conditions are hereby expressly excluded.
                    No servant or agent of the company has authority to add or to vary these conditions. unless such addition or
                    variation is reduced to writing and signed by a duly authorised representative on behalf of the company. In
                    the event of any conflict between these conditions and any conditions contained in any document submitted
                    by the customer. the provisions of these conditions shall prevail.
                </p>
                <p>
                    <span className="u-font-bold">4.</span> The customer warrants that he is either the owner or the authorised agent of the owner of the goods to which
                    any business relates. and further warrants that he. is authorised to accept and is accepting these conditions
                    not only for himself but also as agent for and on behalf of the owner of the goods and all other persons who
                    are or may hereafter become interested in the goods (all such persons being hereinafter called '"the owner")
                </p>
                <p>
                    <span className="u-font-bold">5.</span> The company shall be entitled to perform any of its obligations hereunder by itself or by its parent subsidiary
                    or associated companies. or by any other person. firm or company carrying out the functions of stevedores.
                    Any contract to which these conditions apply is made by the company on its own behalf. and also as agents for
                    and on behalf of any such parent. subsidiary or associated company or such other person. firm or company. and
                    any such company shall be entitled to the benefit of these conditions. The customer will not seek to impose upon
                    any such company a liability greater than that accepted by the company under these conditions.
                </p>
                <p>
                    <span className="u-font-bold">6.</span> Unless otherwise agreed. quotations are given on the basis of immediate acceptance and subject to the right of
                    withdrawal or revision.
                </p>
                <p>
                    <span className="u-font-bold">7.</span> If at any time there is any alteration in the rates of exchange. insurance premiums. labour costs or other
                    charges applicable to the services. or other increases in costs of any nature. whether within the control
                    of the company or not. quotations and charges shall be subject to revision accordingly either with or
                    without notice (at the company's discretion).
                </p>
                <p>
                    <span className="u-font-bold">8.</span> The customer warrants that the description and particulars of any consignments furnished by or on behalf of
                    the customer are accurate.
                </p>
                <p>
                    <span className="u-font-bold">9.</span> Except under special arrangements previously made in writing the company will not accept business relating to
                    valuables. livestock or plants Should any customer nevertheless deliver any such goods to the company or cause the company
                    to handle or deal with any such goods other than under special arrangements previously made in writing. the
                    company shall not be liable for any loss or damage to or in connection with the goods. however caused.
                </p>
                <p>
                    <span className="u-font-bold">10.</span> The company shall not be obliged to make any declaration for the purpose of any statute or contract as to
                    the nature or value of any goods or as to any special interest in delivery. unless required by law or expressly
                    instructed by the customer in writing
                </p>
                <p>
                    <span className="u-font-bold">11.</span> The company shall not be obliged to arrange for the goods to be stevedored or handled separately from the
                    goods of other customers
                </p>
                <p>
                    <span className="u-font-bold">12.</span> The company will not insure the goods unless expressly instructed by the customer in writing The company
                    shall not be obliged to effect a separate insurance on each consignment. but may declare it on an open or
                    general policy. All insurances effected by the company are subject to the usual exceptions and conditions
                    of the policies of the insurance company or underwriters taking the risk. Any claim on the policy shall
                    be made and any negotiations with or proceedings against the insurers shall be conducted by the customer
                    and not by the company. notwithstanding that the premium upon the policy may not be the same rate as that
                    charged to or paid by the customer.
                </p>
                <p>
                    <span className="u-font-bold">13.</span> All sums shall be paid to the company immediately when due without deduction and payment shall not be
                    withheld or deferred on account of any claim. counterclaim or set off of any nature and whether arising under
                    or in relation to this contract or any other contract. The company shall have a general lien on all goods
                    or documents relating to goods in their possession for all sums due at any time from the customer or owner.
                    and shall be entitled to sell or dispose of such goods or documents at the expense of the customer and apply
                    the proceeds in or towards the payment of such sums on 28 days' notice in writing to the customer
                </p>
                <p>
                    <span className="u-font-bold">14.</span> The company shall be entitled at the expense of the customer to sell or dispose of:
                </p>

                <div className="u-indent-1 u-marg-bottom-large">
                    <p>
                        <span className="u-font-bold">(a)</span> on 21 days' notice in writing to the customer or, where the customer cannot be traced after the goods have
                        been held by the company for 90 days. all goods which. in the opinion of the company. cannot be delivered
                        either because they are insufficiently or incorrectly addressed or because they are not collected or accepted
                        by the consignee or for any other reason
                    </p>
                    <p>and</p>
                    <p>
                        <span className="u-font-bold">(b)</span> without notice. perishable goods which are not taken up immediately on arrival or which are insufficiently or
                        incorrectly addressed or marked or which in the opinion of the company would be likely to perish in the course
                        of the carriage. storage or handling.
                    </p>
                </div>

                <p>
                    <span className="u-font-bold">15.</span> Except under special arrangements previously made in writing the company will not accept business relating
                    to dangerous goods. Should any customer nevertheless deliver any such goods to the company or cause the company
                    to handle or deal with any such goods otherwise than under special arrangements previously made in writing the
                    customer shall be liable for all loss or damage caused by or to or connected with the goods however arising and
                    shall indemnify the company against all penalties. claims. demands. costs and expenses arising in connection
                    therewith and the goods may be destroyed or otherwise dealt with at the sole discretion of the company or any other
                </p>

                <p>
                    <span className="u-font-bold">16.</span> Person in whose custody they may be at the relevant time. If such goods are accepted under special
                    arrangements previously made in writing they may nevertheless be so destroyed or dealt with if in the
                    opinion of the company they become a danger to other goods. persons or property.
                </p>

                <p>
                    <span className="u-font-bold">17.</span>Where goods are consigned:
                </p>

                <div className="u-indent-1 u-marg-bottom-large">

                    <p>
                        <span className="u-font-bold">(a)</span> on terms that they shall be paid for on delivery. and if for any reason payment is not made in full. or
                    </p>

                    <p>
                        <span className="u-font-bold">(b)</span> on terms that the company shall only deliver the goods to the consignee on production of a bill of lading.
                        delivery order or similar document and owing to the failure to demand such a document the goods are delivered
                        to the consignee before he has paid for them in full. the company may. at its absolute discretion reimburse the
                        customer with the amount of payment not so made. whereupon the customer shall. on request. assign to the company
                        by an instrument in writing the whole of the customer's title to or interest in the goods and the right to receive
                        payment therefore. and shall co-operate fully with the company in enforcing all rights so assigned.
                    </p>
                </div>

                <p>
                    <span className="u-font-bold">18.</span> Notwithstanding that it may be agreed between the company and the customer that the company's charges in
                    respect of any transaction shall be payable by the consignee or any other person. if such consignee or other
                    person shall fail to pay the same or any part thereof within 28 days of the due demand being made on him. the
                    customer shall be liable to the company therefore without prejudice to the company's rights against such
                    consignee or other person
                </p>

                <p><span className="u-font-bold">19.</span> Limitation of liability</p>

                <div className="u-indent-1 u-marg-bottom-large">

                    <p>
                        <span className="u-font-bold">a)</span> This condition sets out the entire financial liability of the company (including any liability for the acts
                        or omissions of its employees. agents and sub-contractors) to the customer in respect of:
                    </p>

                    <div className="u-indent-1 u-marg-bottom-large">
                        <p><span className="u-font-bold">(i)</span> any breach of the agreement</p>
                        <p><span className="u-font-bold">(ii)</span> any use made by the customer of the services and</p>
                        <p><span className="u-font-bold">(iii)</span> any representation. statement or tortuous act or omission (including negligence) arising under or in
                            connection with the agreement.</p>
                    </div>

                    <p>
                        <span className="u-font-bold">b)</span> All warranties. conditions and other terms implied by statute or common law are. to the fullest extent
                        permitted by law. excluded from the agreement
                    </p>

                    <p>
                        <span className="u-font-bold">c)</span> nothing in these conditions limits or excludes the liability of the company
                    </p>

                    <div className="u-indent-1 u-marg-bottom-large">
                        <p><span className="u-font-bold">(I)</span> for death or personal injury resulting from negligence: or</p>
                        <p><span className="u-font-bold">(ii)</span> for any damage or liability incurred by the customer as a'result of fraud or fraudulent misrepresentation
                            by the company.</p>
                    </div>

                    <p>
                        <span className="u-font-bold">d)</span> Subject to the above
                    </p>

                    <div className="u-indent-1 u-marg-bottom-large">
                        <p><span className="u-font-bold">(i)</span> the company shall not be liable. whether 111 tort (including for negligence or breach of statutory duty).
                            contract misrepresentation or otherwise for</p>

                        <div className="u-indent-1 u-marg-bottom-large">
                            <ol>
                                <li>loss of profits: or</li>
                                <li>loss of business. or</li>
                                <li>depletion of goodwill and/or similar losses: or</li>
                                <li>loss of anticipated savings: or</li>
                                <li>loss of goods: or</li>
                                <li>loss of contract or</li>
                                <li>loss of use: or</li>
                                <li>any special. indirect consequential or pure economic loss. costs. damages, charges or expenses.</li>
                            </ol>
                        </div>

                        <p>
                            <span className="u-font-bold">(ii)</span> The company's total liability in contract tort (including negligence or breach of statutory duty),
                            misrepresentation. restitution or otherwise arising in connection with the performance or contemplated
                            performance of the agreement shall be limited to the price paid for the services or where the customer is
                            charged on a vessel-by-vessel basis to the individual charge for the vessel in connection with which the
                            liability arises.
                        </p>
                    </div>

                    <p>
                        <span className="u-font-bold">e)</span>
                    </p>

                    <div className="u-indent-1 u-marg-bottom-large">
                        <p>
                            <span className="u-font-bold">i)</span> The company shall only be responsible for any loss of or damage to goods or for any non-delivery or misdelivery
                            if it is proved that the loss. damage, nondelivery or misdelivery occurred , whilst the goods were in the custody
                            of the company and under its actual control and that such loss. damage, nondelivery or misdelivery was due to the
                            wilful neglect or default of the company or its own employees.
                        </p>

                        <p>
                            <span className="u-font-bold">(ii)</span> The company shall only be liable for any non-compliance or mis-compliance with instructions given to it if it
                            is proved that the same was caused by the wilful neglect or default or default of the company or its own employees.
                        </p>

                        <p>
                            <span className="u-font-bold">(iii)</span> Save as aforesaid. the company shall be under no liability whatsoever however arising and whether in respect
                            of or in connection with any goods of any instructions. business. advice. information. handling storage or service
                            or otherwise.
                        </p>
                    </div>
                </div>

                <p><span className="u-font-bold">20.</span> Exclusion of liability</p>
                <p>In any event the company shall not incur any liability in respect of:</p>

                <div className="u-indent-1 u-marg-bottom-large">
                    <p>
                        <span className="u-font-bold">a)</span> damage wholly or partly resulting from the nature and/or condition of the means of transport or the nature
                        and/or condition of the goods:
                    </p>

                    <p>
                        <span className="u-font-bold">b)</span> damage which is unavoidable in the applied method of working indicated to the customer if no objection has
                        been raised to such method by or on behalf of the customer or those interested:
                    </p>

                    <p>
                        <span className="u-font-bold">c)</span> damage due to any difference in weight. quality. contamination. mingling including damage caused by dust
                        and/or presence of foreign objects or particles in the goods unless caused by gross negligence or recklessness
                        of the company or its employees:
                    </p>

                    <p>
                        <span className="u-font-bold">d)</span> damage caused by or resulting from work other than the loading. unloading. handling. storage. warehousing.
                        stockpiling and delivery. This exemption includes such activities as placing of lamps. carrying out repairs.
                        manipulating hatches. beams. cross beams or moving or removing vessels or vehicles.
                    </p>

                    <p>
                        <span className="u-font-bold">e)</span> damage resulting from failure breakage or other defects of the equipment used by the company unless it
                        can be proved that the equipment was not in a good state of maintenance and did not conform to the requirements
                        if any laid down by the relevant authorities in this respect:
                    </p>

                    <p>
                        <span className="u-font-bold">f)</span> damage resulting from non-compliance by third parties with regulations laid down by the relevant authorities
                        or issued by the company:
                    </p>

                    <p>
                        <span className="u-font-bold">g)</span> damage arising from any act or any neglect of a third party whether or not the third party was employed by
                        or on behalf of the company:
                    </p>

                    <p>
                        <span className="u-font-bold">h)</span> damage done to other goods on board during the performance of the services by the company other than damage
                        resulting from the gross negligence or wilful default of the company or its employees:
                    </p>

                    <p>
                        <span className="u-font-bold">i)</span> despatch money lost or demurrage due in respect of barges. lighters. trucks unless caused by the gross
                        negligence or wilful default of the company or its employees:
                    </p>

                    <p>
                        <span className="u-font-bold">j)</span> delay arising from the repair of damage where such repair is carried out during or after completion of
                        loading or unloading unless the damage has been caused by the gross negligence or wilful default of the
                        company or its employees
                    </p>
                </div>

                <p>
                    <span className="u-font-bold">22.</span> Pending forwarding and delivery. goods may be warehoused or otherwise held at any place or places
                    at the sole discretion of the company at the customer's risk and expense
                </p>

                <p>
                    <span className="u-font-bold">23.</span>The company shall be exempt from all liability whatever for deficiency. loss. damage or mis-delivery of
                    or to goods. or for delay arising  out of or caused or contributed to by any of the following:
                </p>

                <div className="u-indent-1 u-marg-bottom-large">
                    <p>
                        <span className="u-font-bold">(A)</span> vermin. moths. worms. weevils or Insects:
                    </p>

                    <p>
                        <span className="u-font-bold">(B)</span> fire. explosion. act of god. storm or Flood:
                    </p>

                    <p>
                        <span className="u-font-bold">(C)</span> strikes. combinations or lock-outs of any persons:
                    </p>

                    <p>
                        <span className="u-font-bold">(D)</span> civil commotion;
                    </p>

                    <p>
                        <span className="u-font-bold">(E)</span> enemies of the state;
                    </p>

                    <p>
                        <span className="u-font-bold">(F)</span> inherent vice or quality of the goods.
                    </p>

                    <p>
                        <span className="u-font-bold">(G)</span> improper. insufficient indistinct or erroneous marking or addressing of goods
                    </p>

                    <p>
                        <span className="u-font-bold">(H)</span> nuclear radiation or fallout
                    </p>
                </div>

                <p>
                    <span className="u-font-bold">24.</span> Deficiency or loss of or damage to goods must be notified in writing to the company within 21 days of the
                    removal of the goods from the company's premises. If this condition is not complied with the company shall be
                    exempt from all liability whatever for any deficiency. loss or damage however or whenever caused.
                </p>

                <p>
                    <span className="u-font-bold">25.</span> The customer shall indemnify the company against all duties. taxes. liabilities. whether or not arising
                    out of the negligence of the company. its servants or agents suffered or incurred by the company in the
                    performance of its obligations hereunder. including any liability to indemnify any other person against
                    claims made against such other person by the customer or by the owner.
                </p>

                <p>
                    <span className="u-font-bold">26.</span> Except where the company is instructed to pack the goods. the customer warrants that all goods have
                    been properly. sufficiently and professionally packed and/or prepared.
                </p>

                <p>
                    <span className="u-font-bold">27.</span> Where in these conditions any matter is to be determined in accordance with the opinion of the company.
                    the certificate of a director. chief executive or the secretary of the company for the time being shall be
                    conclusive evidence as to any matter so certified.
                </p>

                <p>
                    <span className="u-font-bold">28.</span> The customer shall be liable to pay to the company. on demand. all reasonable costs. charges or losses
                    sustained or incurred by the company (including any direct. indirect or consequential losses. loss of profit
                    and loss of reputation. loss or damage to property and those arising from injury to or death of any person
                    and loss of opportunity to deploy resources elsewhere) arising directly or indirectly from the customer's
                    fraud, negligence. failure to perform or delay in the performance of any of its obligations under the
                    agreement. subject of the company confirming such costs. charges and losses to the customer in writing
                </p>

                <p>
                    <span className="u-font-bold">29</span> On termination of the agreement for any reason
                </p>

                <div className="u-indent-1 u-marg-bottom-large">

                    <p>
                        <span className="u-font-bold">a)</span> The customer shall immediately pay to the company all of the company's outstanding unpaid invoices and
                        interest and, in respect of servies supplied but for which no invoice has been submitted. the company
                        may submit an invoice. which shall be payable immediately on receipt:
                    </p>

                    <p>
                        <span className="u-font-bold">b)</span> The accrued rights of the parties as at termination and the continuation of any provision expressly stated
                        to survive or implicitly surviving termination. shall not be affected.
                    </p>

                </div>


                <p>
                    <span className="u-font-bold">30.</span> These conditions. and any act or contract to which they apply. shall be governed by the laws of the
                    Northern Ireland and within the exclusive jurisdiction of the courts of Northern Ireland.
                </p>

            </div>
        </p>



    </div>

    </div>

        <div className="c-splitter"></div>
    </StandardLayout>